import { Link as RouterLink } from "react-router-dom";

import {
	Box,
	Heading,
	Container,
	Text,
	Button,
	Stack,
	Link,
	HStack
} from '@chakra-ui/react';
import { FaEquals, FaListOl, FaSearch } from 'react-icons/fa';



const HomeIntro = () => {

	return (

		<Container maxW={'3xl'}>
			<Stack
				as={Box}
				textAlign={'center'}
				spacing={{ base: 8, md: 14 }}
				py={{ base: 20, md: 36 }}>
				<Heading
					fontWeight={600}
					fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
					lineHeight={'110%'}>
					English Quran<br />
					<Text as={'span'} color={'#754d29'}>
						Desktop, Tablet & Mobile Friendly</Text>
				</Heading>
				<Text fontSize={'xl'}>
					Lightweight, Installable and Usable Offline
				</Text>

				<HStack
					direction={'column'}
					spacing={3}
					align={'center'}
					alignSelf={'center'}
					position={'relative'}
				>

					<Link as={RouterLink} to="/surah">
						<Button
							bgColor={"#c2986a"}
							leftIcon={<FaListOl />}
							rounded={'full'}
							px={6}
						>Surah List
						</Button>
					</Link>

					<Link as={RouterLink} to={"/juz"}>
						<Button
							leftIcon={<FaEquals />}
							rounded={'full'}
							px={6}
							bgColor={"#c2986a"}
						>Juz List
						</Button>
					</Link>

				</HStack>

				<Link as={RouterLink} to={"/search"}>
					<Button
						leftIcon={<FaSearch />}
						rounded={'full'}
						px={6}
						bgColor={"#c2986a"}
					>Search Quran
					</Button>
				</Link>

			</Stack>



		</Container >

	);
}


export default HomeIntro