const features =
	[
		{
			id: 1,
			title: 'Content',
			text: 'Quran in Arabic and English Translation by Saheeh International. Browse by Surah or Juz. Search the Quran for English Words all whilst being offline, saving on data costs.'
		},
		{
			id: 2,
			title: 'Technology',
			text: 'May be accessed from your desktop, tablet, mobile phone, television and other devices. Compatible on most platforms including Android, iOS, Windows, Linux and more.'
		},
		{
			id: 3,
			title: 'Accessibility',
			text: 'No Ads and completely FREE. To install, simply “Add to Home Screen” via pop-up or via Browser Menu. Yes! Its that simple. No App Stores involved.'
		}
	]

export default features