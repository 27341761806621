import { BrowserRouter, Route, Routes } from "react-router-dom";

import React, { Suspense } from 'react';

import { Container, Box } from "@chakra-ui/react";


// Core Pages / Layout: This ensures only the front page is loaded and thus added to precache.
import Header from '../common/header'
import Home from '../common/home'

// Lazy Components
const About = React.lazy(() => import('../common/about'));
const Contact = React.lazy(() => import('../common/contact'));
const Page404 = React.lazy(() => import('../common/404'));
const Changelog = React.lazy(() => import('../common/changelog'));
const Settings = React.lazy(() => import('../common/settings'));

// Lazy Components (only because Quran JSON is so massive)
const Search = React.lazy(() => import('../components/search/search'));
const Surah = React.lazy(() => import('../components/surah-list'));
const SurahSingle = React.lazy(() => import('../components/surah/surah'));
const Juz = React.lazy(() => import('../components/juz-list'));
const JuzSingle = React.lazy(() => import('../components/juz/juzSingle'));
const Reciters = React.lazy(() => import('../components/reciter-list'));


const AppRoutes = () => {


	// Used for Surah Mapping /1 = Fatiha
	// Avoids accessing the JSON now to prevent JS bloating
	const SurahRoutes = Array.from(Array(114), (x, index) => index + 1);
	const JuzRoutes = Array.from(Array(30), (x, index) => index + 1);


	return (

		<BrowserRouter>

			<Header />			

			<Suspense fallback={
				<Container maxW='container.lg' mt={10} mb={20} centerContent>
					<Box>Loading...</Box>
				</Container>
			}>


				<Routes>

					<Route path="/" element={<Home />} />

					<Route path="/about" element={<About />} />	
					<Route path="/contact" element={<Contact />} />
					<Route path="/changelog" element={<Changelog />} />
					<Route path="/settings" element={<Settings />} />

					<Route path="/surah" element={<Surah />} />
					<Route path="/juz" element={<Juz />} />
					<Route path="/search" element={<Search />} />

					<Route path="/reciters" element={<Reciters />} />

					{
						// Create a Route for every Surah and Pass the Index Value to the Component
						SurahRoutes.map((surah, index) => (
							<Route
								key={index}
								path={"/surah/" + surah}
								element={<SurahSingle surahNumber={surah} />}
							/>
						))
					}

					{
						// Create a Route for every Surah and Pass the Index Value to the Component
						JuzRoutes.map((juz, index) => (
							<Route
								key={index}
								path={"/juz/" + juz}
								element={<JuzSingle JuzNumber={juz} />}
							/>
						))
					}

					<Route path="*" element={<Page404 />} />

				</Routes>

			</Suspense>

		</BrowserRouter >

	);
}

export default AppRoutes;