import './App.css';

// 1. import `ChakraProvider` component
import { ChakraProvider } from '@chakra-ui/react'

import AppRoutes from "./utils/App-Routes";
import AppUI from './utils/App-UI';


function App() {

	return (

		<ChakraProvider theme={AppUI()}>

			<div className="App">
				<AppRoutes />
			</div>

		</ChakraProvider>
	)
}

export default App;
