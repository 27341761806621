// 1. Import `extendTheme`
import { extendTheme, type ThemeConfig } from "@chakra-ui/react"
// import { ThemeConfig } from '@chakra-ui/react'

import '@fontsource/open-sans/700.css'
import "@fontsource/sora"


export function AppUI() {

	// 2. Add your color mode config
	// const config: ThemeConfig = {
	// 	initialColorMode: 'light',
	// 	useSystemColorMode: false,

	// }

	// 3. extend the theme
	const theme = extendTheme({
		// config, 
		fonts: {
			heading: 'Open Sans, sans-serif',
			body: 'Sora, sans-serif',
		},
		styles: {
			global: {
				h1: {
					fontSize: 'sm',
					fontWeight: 'bold',
				},
				h2: {
					fontSize: 'lg',
					fontWeight: 'bold',
				},
				h3: {
					fontSize: 'lg'
				},
				h4: {
					fontSize: 'md'
				}
			}
		}
	})

	return theme

}

export default AppUI;