import {
	Box,
	Container,
	Heading,
	SimpleGrid,
	Icon,
	Text,
	Stack,
	HStack,
	VStack,
	Center,
	useColorMode,
	AspectRatio,
	Button
} from '@chakra-ui/react';

import { CheckIcon } from '@chakra-ui/icons';

import features from './home-features-list'

import { ReactComponent as MCLogoLight } from '../assets/images/mc-logo-light.svg';
import { ReactComponent as MCLogoDark } from '../assets/images/mc-logo-dark.svg';


const HomeFeatures = () => {

	const { colorMode } = useColorMode()

	return (
		<Box p={4} mb={20}>
			<Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'} mb={20}>


				<Text as="b" fontSize={'xl'}>
					Brought to you by
				</Text>

				<Center>
					{

						colorMode === 'light' &&
						<MCLogoLight />
					}
					{
						colorMode === 'dark' &&
						<MCLogoDark />

					}

				</Center>

				<Box>

					<AspectRatio maxW='' ratio={1} mb={10}>
						<iframe
							title='Mufti Menk Video on Muslim Central'
							src='https://www.youtube.com/embed/tnuMPZiMcgw'
							allowFullScreen
						/>
					</AspectRatio>

					<Box>
						<Text as="em">"I really think this is one of the greatest causes one could support."</Text>
					</Box>
					<Text as="cite">Mufti Ismail Menk (Advisory Director)</Text>

					<Box>
						<Button
							mt={5}
							bgColor={"#c2986a"}
							as='a'
							href='https://muslimcentral.com/donate/'
							target='_blank'>↗ Donate</Button>
					</Box>

				</Box>

			</Stack>




			<Center>

				<VStack>


					<Box mt={20}>
						<Heading fontSize={'4xl'} color="#754d29">Features</Heading>
					</Box>

				</VStack>

			</Center>

			<Container maxW={'6xl'} mt={10}>
				<SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={20}>
					{features.map((feature) => (
						<HStack key={feature.id} align={'top'}>
							<Box color={'red.400'} px={2}>
								<Icon as={CheckIcon} color={"#754d29"} />
							</Box>
							<VStack align={'start'}>
								<Text fontWeight={600}>{feature.title}</Text>
								<Text>{feature.text}</Text>
							</VStack>
						</HStack>
					))}
				</SimpleGrid>
			</Container>
		</Box >
	);
}

export default HomeFeatures